define("frontend2/components/bsz-dropdown", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <BsDropdown as |dd| >
      <dd.button class="dd-button btn-block form-control was-validated" block=true>
          <span class="pull-left">
              {{#if selectedItem}}
                  {{yield selectedItem}}
              {{else}}
                  {{placeholder}}
              {{/if}}
          </span>
          <span class="caret"></span>
      </dd.button>
      <dd.menu as |menu|>
          {{#each this.listWithSelected as |i|}}
              <menu.item>
                  {{#if i._dd_selected}}
                      <a class="selected" {{action "selectItem" i}}>
                        {{yield i}}
                      </a>
                  {{else}}
                      <a class="dd-item"  {{action "selectItem" i}}>
                        {{yield i}}
                      </a>
                  {{/if}}
              </menu.item>
          {{/each}}
      </dd.menu>
  </BsDropdown>
  
  */
  {
    "id": "iPbaBIRg",
    "block": "{\"symbols\":[\"dd\",\"menu\",\"i\",\"&default\"],\"statements\":[[8,\"bs-dropdown\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"button\"]],[[24,0,\"dd-button btn-block form-control was-validated\"],[24,\"block\",\"true\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"span\"],[14,0,\"pull-left\"],[12],[2,\"\\n\"],[6,[37,1],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                \"],[18,4,[[35,3]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                \"],[1,[34,2]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n        \"],[10,\"span\"],[14,0,\"caret\"],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,[32,1,[\"menu\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"listWithSelected\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,[32,2,[\"item\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,3,[\"_dd_selected\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                    \"],[11,\"a\"],[24,0,\"selected\"],[4,[38,0],[[32,0],\"selectItem\",[32,3]],null],[12],[2,\"\\n                      \"],[18,4,[[32,3]]],[2,\"\\n                    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                    \"],[11,\"a\"],[24,0,\"dd-item\"],[4,[38,0],[[32,0],\"selectItem\",[32,3]],null],[12],[2,\"\\n                      \"],[18,4,[[32,3]]],[2,\"\\n                    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"            \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"    \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"placeholder\",\"selectedItem\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "frontend2/components/bsz-dropdown.hbs"
    }
  });

  let BszDropdownComponent = (_dec = Ember._action, (_class = class BszDropdownComponent extends _component.default {
    get placeholder() {
      return this.args.placeholder || 'Please select...';
    }

    selectItem(selectedValue) {
      this.args.onChange(this.args.selectValueProperty ? selectedValue[this.args.selectValueProperty] : selectedValue);
    }

    get listWithSelected() {
      return this.args.list.map(item => {
        if (this.args.selectValueProperty) {
          item._dd_selected = item[this.args.selectValueProperty] === this.args.value;
        } else {
          if (this.args.value) {
            // Try and use ember get (for proxy objects)
            if (this.args.value.get) {
              item._dd_selected = item.id === this.args.value.get('id');
            } else {
              item._dd_selected = item.id === this.args.value.id;
            }
          }
        }

        return item;
      });
    }

    get selectedItem() {
      if (!this.args.list) {
        console.warn('List is empty, nothing to select');
        return null;
      }

      return this.args.list.find(item => {
        if (this.args.selectValueProperty) {
          return this.args.value === item[this.args.selectValueProperty];
        } else {
          // Handle null values (try and find object with null id)
          if (!this.args.value) {
            return item.id === null;
          } // Try and use ember get (for proxy objects)


          if (this.args.value.get) {
            return this.args.value.get('id') === item.id;
          } else {
            return this.args.value.id === item.id;
          }
        }
      });
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "selectItem", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "selectItem"), _class.prototype)), _class));
  _exports.default = BszDropdownComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, BszDropdownComponent);
});