define("frontend2/templates/security/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sfCftjQq",
    "block": "{\"symbols\":[],\"statements\":[[3,\" Breadcrumb start \"],[2,\"\\n\"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"\\n      \"],[8,\"link-to\",[],[[\"@route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[2,\"Home\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"active\"],[12],[2,\"Security\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[3,\" Breadcrumb end \"],[2,\"\\n\\n\"],[3,\" Page Header start \"],[2,\"\\n\"],[10,\"div\"],[14,0,\"container page-header\"],[12],[2,\"\\n  \"],[10,\"h2\"],[12],[2,\"\\n    \"],[8,\"fa-icon\",[],[[\"@icon\",\"@class\"],[\"shield-alt\",\"pull-left\"]],null],[2,\"\\n    Security and abuse protection\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"container \"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-xs-12 col-sm-6 col-md-4 col-lg-3 mainpanel\"],[12],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\",\"@class\",\"@tagName\"],[\"security.networks\",\"panel panel-default\",\"div\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"panel-body text-center\"],[14,5,\"cursor:pointer\"],[12],[2,\"\\n        \"],[8,\"fa-icon\",[],[[\"@icon\",\"@class\"],[\"server\",\"fa-2x\"]],null],[2,\"\\n        \"],[10,\"h2\"],[12],[2,\"\\n          Networks\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[12],[2,\"Trusted networks management\"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "frontend2/templates/security/index.hbs"
    }
  });

  _exports.default = _default;
});