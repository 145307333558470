define("frontend2/routes/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LoginRoute extends Ember.Route {
    // Check route is unauthenticated, if not goto index page
    beforeModel() {
      if (!this.apiSession.isAuthenticated) {
        return;
      }

      console.debug('User already authenticated, redirecting to index page');
      this.transitionTo('index');
    }

  }

  _exports.default = LoginRoute;
});