define("frontend2/services/api-session", ["exports", "fetch", "frontend2/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ApiSessionService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, (_class = class ApiSessionService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "loginErrorMsg", _descriptor3, this);

      _initializerDefineProperty(this, "current", _descriptor4, this);

      _initializerDefineProperty(this, "impersonatedCustomer", _descriptor5, this);

      _initializerDefineProperty(this, "embedAccessToken", _descriptor6, this);

      _initializerDefineProperty(this, "showEmbeddedHeader", _descriptor7, this);
    }

    get isAuthenticated() {
      return this.session.isAuthenticated;
    }

    get isEmbeddedMode() {
      return Boolean(this.embedAccessToken);
    }

    async login(identification, password) {
      console.debug(`Trying to create session for: ${identification}`);
      return new Promise((resolve, reject) => {
        this.session.authenticate('authenticator:oauth2', identification, password).then(() => {
          console.debug('Successfully authenticated');
          this.loadCurrentUserInfo();
          resolve();
        }).catch(error => {
          console.error('Unable to authenticate new session:');
          console.error(error);

          if (error instanceof TypeError) {
            reject(error.message);
          } else {
            reject(error.responseJSON.error_description);
          }
        });
      });
    }

    logout() {
      console.debug('Invalidating session');
      this.session.invalidate();
      this.current = null; // Force page reload to clean up

      window.location.reload(true);
    }

    loadCurrentUserInfo() {
      // Just return is already loaded
      if (this.current || !this.isAuthenticated) {
        return true;
      }

      console.debug('Loading current user information');

      if (this.isEmbeddedMode) {
        this.impersonatedCustomer = null;
      } else {
        const impCid = localStorage.getItem('impCid');
        this.impersonatedCustomer = impCid ? this.store.findRecord('customer', impCid) : null;
      }

      return this.store.queryRecord('current-user', {
        _endpoint: 'users/current'
      }).then(record => {
        this.current = record;
        console.debug('Current user was loaded');
      });
    }

    setEmbeddedMode(configToken, showEmbeddedHeader) {
      const configTokenUnpacked = JSON.parse(atob(configToken));
      this.embedAccessToken = configTokenUnpacked.access;
      this.showEmbeddedHeader = Boolean(Number(showEmbeddedHeader));
      console.info(`Enable embed mode, token: ${this.embedAccessToken} (show headers: ${this.showEmbeddedHeader})`);
    }

    startImpersonating(customer) {
      console.info(`Start impersonate customer id ${customer.id}`); // this.store.unloadAll();

      this.loadCurrentUserInfo();
      this.impersonatedCustomer = customer;
      localStorage.setItem('impCid', customer.id);
    }

    stopImpersonating() {
      console.info('Stop impersonating'); // Clear store and remove impersonation info

      this.store.unloadAll();
      this.impersonatedCustomer = null;
      localStorage.removeItem('impCid'); // Remove current user to force reload

      this.current = null;
      this.loadCurrentUserInfo();
    }
    /*
     * API Methods
     * -------------------------------
     */
    // Send invitation mail to user


    async inviteUser(user) {
      console.info(`Inviting user ${user.name}`);
      return this.authenticatedRequest('/users/invite_user/', 'POST', {
        user_id: user.id
      });
    } // Activate user from token


    async activateUser(token, password) {
      console.info(`Activate user with token ${token}`);
      return this.unauthenticatedRequest('/users/activate/', 'POST', {
        token: token,
        new_password: password
      });
    }

    async getTokenInfo(token) {
      console.info(`Get information for token ${token}`);
      return this.unauthenticatedRequest('/users/get_token_info/', 'POST', {
        token: token
      });
    } // Request password recovery


    async reqPasswordRecover(email) {
      return this.unauthenticatedRequest('/users/req_password_recover/', 'POST', {
        email: email
      });
    } // Recover password with token and password


    async recoverPassword(token, password) {
      return this.unauthenticatedRequest('/users/recover_password/', 'POST', {
        token: token,
        new_password: password
      });
    } // Start number verification


    async verifyPhoneNumber(phoneNumber) {
      console.info(`Starting phone number verification for ${phoneNumber.labelStr}`);
      return this.authenticatedRequest(`/phone-numbers/${phoneNumber.id}/verify`, 'GET');
    } // Start number deactivation


    async deactivatePhoneNumber(phoneNumber) {
      console.info(`Starting phone number deactivation for ${phoneNumber.labelStr}`);
      return this.authenticatedRequest(`/phone-numbers/${phoneNumber.id}/deactivate`, 'GET');
    } // Start number termination


    async terminatePhoneNumber(phoneNumber) {
      console.info(`Starting phone number termination for ${phoneNumber.labelStr}`);
      return this.authenticatedRequest(`/phone-numbers/${phoneNumber.id}/terminate`, 'GET');
    } // Start number termination


    async getSignedUploadURL() {
      console.info(`Fetching signed upload url for media file`);
      return this.authenticatedRequest(`/media-files/get-upload-url`, 'GET');
    }
    /*
     * API Request helpers
     * ---------------------------------
     */
    // Perform unauthenticated request


    async unauthenticatedRequest(path, method, data) {
      console.debug(`Performing unauthenticated request [${method}] ${path}`); // Create headers first

      let headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      };
      return this.doFetch(path, method, headers, data);
    } // Perform authenticated request


    async authenticatedRequest(path, method, data) {
      if (!this.isAuthenticated) {
        console.error('Unable to perform request, not authenticated');
        return Promise.reject();
      }

      console.debug(`Performing authenticated request [${method}] ${path}`); // Create headers first

      const authToken = `Bearer ${this.session.data.authenticated.access_token}`;
      let headers = {
        'Content-Type': 'application/json',
        Authorization: authToken,
        Accept: 'application/json'
      };
      const imp_id = localStorage.getItem('impCid');

      if (imp_id) {
        headers['X-USE-CUSTOMER-ID'] = imp_id;
      }

      return this.doFetch(path, method, headers, data);
    } // Reorganise fetch promises so fail is non 2xx responses


    async doFetch(path, method, headers, data) {
      return new Promise((resolve, reject) => {
        (0, _fetch.default)(_environment.default.APP.API_HOST + '/api' + path, {
          method: method,
          headers: headers,
          body: JSON.stringify(data)
        }).then(response => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then(data => {
              resolve(data);
            });
          } else {
            response.json().then(error => {
              error._status = response.status;
              reject(error);
            });
          }
        }).catch(error => {
          this.notify.error('An unexpected error occurred');
          console.error(error);
          reject(error);
        });
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loginErrorMsg", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "current", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "impersonatedCustomer", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "embedAccessToken", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showEmbeddedHeader", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  })), _class));
  _exports.default = ApiSessionService;
});