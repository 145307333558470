define("frontend2/models/extended-call-info", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExtendedCallInfoModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('date'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('number'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('number'), _dec8 = (0, _model.attr)('number'), _dec9 = (0, _model.attr)('number'), _dec10 = (0, _model.attr)('number'), _dec11 = (0, _model.attr)('number'), _dec12 = (0, _model.attr)('number'), _dec13 = (0, _model.attr)('number'), _dec14 = (0, _model.attr)('number'), _dec15 = (0, _model.attr)('number'), _dec16 = (0, _model.attr)('number'), _dec17 = (0, _model.attr)('number'), _dec18 = (0, _model.attr)('number'), _dec19 = (0, _model.attr)('number'), _dec20 = (0, _model.attr)('number'), (_class = class ExtendedCallInfoModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "callUuid", _descriptor, this);

      _initializerDefineProperty(this, "createTime", _descriptor2, this);

      _initializerDefineProperty(this, "sipCallId", _descriptor3, this);

      _initializerDefineProperty(this, "leg", _descriptor4, this);

      _initializerDefineProperty(this, "remoteIpAddress", _descriptor5, this);

      _initializerDefineProperty(this, "rtpCodec", _descriptor6, this);

      _initializerDefineProperty(this, "rtpInMos", _descriptor7, this);

      _initializerDefineProperty(this, "rtpInQualityPct", _descriptor8, this);

      _initializerDefineProperty(this, "rtpInJitterMinVariance", _descriptor9, this);

      _initializerDefineProperty(this, "rtpInJitterMaxVariance", _descriptor10, this);

      _initializerDefineProperty(this, "rtpInJitterLossRate", _descriptor11, this);

      _initializerDefineProperty(this, "rtpInJitterBurstRate", _descriptor12, this);

      _initializerDefineProperty(this, "rtpInMeanInterval", _descriptor13, this);

      _initializerDefineProperty(this, "rtpInMediaBytes", _descriptor14, this);

      _initializerDefineProperty(this, "rtpInPacketCount", _descriptor15, this);

      _initializerDefineProperty(this, "rtpInPacketSkip", _descriptor16, this);

      _initializerDefineProperty(this, "rtpOutMediaBytes", _descriptor17, this);

      _initializerDefineProperty(this, "rtpOutPacketCount", _descriptor18, this);

      _initializerDefineProperty(this, "rtpOutPacketSkip", _descriptor19, this);

      _initializerDefineProperty(this, "sipTermStatusCode", _descriptor20, this);
    }

    // Good > 80%
    get qualityIsGood() {
      return this.rtpInQualityPct > 80;
    } // Medium 50-80%


    get qualityIsMedium() {
      return this.rtpInQualityPct > 50 && this.rtpInQualityPct <= 80;
    } // Bad < 50%


    get qualityIsBad() {
      return this.rtpInQualityPct < 50;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "callUuid", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "createTime", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sipCallId", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "leg", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "remoteIpAddress", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "rtpCodec", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "rtpInMos", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "rtpInQualityPct", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "rtpInJitterMinVariance", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "rtpInJitterMaxVariance", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "rtpInJitterLossRate", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "rtpInJitterBurstRate", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "rtpInMeanInterval", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "rtpInMediaBytes", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "rtpInPacketCount", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "rtpInPacketSkip", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "rtpOutMediaBytes", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "rtpOutPacketCount", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "rtpOutPacketSkip", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "sipTermStatusCode", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ExtendedCallInfoModel;
});