define("frontend2/routes/admin/customers/index", ["exports", "frontend2/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let AdminCustomersIndexRoute = (_dec = Ember._action, (_class = class AdminCustomersIndexRoute extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "controllerName", 'admin/customer-list');

      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        },
        inactive: {
          refreshModel: true
        },
        search: {
          refreshModel: true
        }
      });
    }

    model(params) {
      let q = {};

      if (params.search) {
        q.search = params.search;
      }

      if (params.page) {
        q.page = params.page;
      }

      if (!params.inactive) {
        q['has_activity'] = 1;
      }

      q.include = "home_country,subscriptions,currency,aggregated_statistics";
      q.page_size = 50; // Load customers with subscription, so we can show subscription sales on customer list
      // We use query here because findAll makes premature resolve of promise, causing issues for loading substate

      return this.store.query('customer', q);
    }

    loading(transition)
    /* originRoute */
    {
      let controller = this.controllerFor('admin/customer');
      controller.set('isLoading', true);
      transition.promise.finally(function () {
        controller.set('isLoading', false);
      }); // Show page spinner if coming from a different page

      return !(transition.from && transition.from.name === 'admin.customers.index');
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "loading", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype)), _class));
  _exports.default = AdminCustomersIndexRoute;
});