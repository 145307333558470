define("frontend2/controllers/sip-account", ["exports", "frontend2/controllers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SipAccountController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class SipAccountController extends _base.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "showCreateDialog", _descriptor, this);

      _initializerDefineProperty(this, "showAddNetworkDlg", _descriptor2, this);

      _initializerDefineProperty(this, "showAdvanced", _descriptor3, this);

      _defineProperty(this, "phoneNumbers", []);

      _defineProperty(this, "shortCids", []);

      _defineProperty(this, "networkList", []);

      _defineProperty(this, "userList", []);
    }

    get userListWithNone() {
      return [{
        id: null,
        name: '(No user assigned to account)'
      }].concat(this.userList.toArray());
    }

    get phoneNumbersWithNone() {
      return [{
        id: null,
        name: '(Use Smart CID)'
      }].concat(this.phoneNumbers.toArray());
    }

    get shortCidsWithNone() {
      return [{
        id: null,
        name: '(Do not use Smart CID)'
      }].concat(this.shortCids.toArray());
    }

    openCreateDialog() {
      this.showCreateDialog = true;
    }

    addNetwork(network) {
      this.model.allowedNetworks.pushObject(network);
      this.showAddNetworkDlg = false;
    }

    deleteNetwork(network) {
      this.model.allowedNetworks.removeObject(network);
    }
    /*
     * UI Helpers
     */


    get sipTrunkList() {
      return this.model.filter(account => account.type === 1);
    }

    get sipUserList() {
      return this.model.filter(account => account.type === 3);
    }

    get sipFlexList() {
      return this.model.filter(account => account.type === 4);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showCreateDialog", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showAddNetworkDlg", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showAdvanced", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "openCreateDialog", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "openCreateDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addNetwork", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "addNetwork"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteNetwork", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "deleteNetwork"), _class.prototype)), _class));
  _exports.default = SipAccountController;
});