define("frontend2/routes/admin/customers/edit/phone-numbers/edit", ["exports", "frontend2/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class AdminCustomersEditPhoneNumbersEditRoute extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "controllerName", 'admin/customer');
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.didCountryList = this.store.query('country', {
        'did_available': 'True'
      });
    }

    model(params) {
      return this.store.findRecord('partner-phone-number', params.number_id);
    }

  }

  _exports.default = AdminCustomersEditPhoneNumbersEditRoute;
});