define("frontend2/routes/short-cid/edit", ["exports", "frontend2/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ShortCidEditRoute extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "controllerName", 'short-cid');
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.phoneNumberList = this.store.findAll('phone-number', {
        include: 'country'
      });
    }

    model(params) {
      return this.store.findRecord('short-cid', params.cid_id, {
        include: 'phone_numbers'
      });
    }

  }

  _exports.default = ShortCidEditRoute;
});