define("frontend2/models/network", ["exports", "@ember-data/model", "ember-changeset-validations/validators"], function (_exports, _model, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let NetworkModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), (_class = class NetworkModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "address", _descriptor2, this);

      _initializerDefineProperty(this, "netmask", _descriptor3, this);

      _defineProperty(this, "netmaskList", [{
        value: '255.255.255.255',
        name: '1 (255.255.255.255)'
      }, {
        value: '255.255.255.254',
        name: '2 (255.255.255.254)'
      }, {
        value: '255.255.255.252',
        name: '4 (255.255.255.252)'
      }, {
        value: '255.255.255.248',
        name: '8 (255.255.255.248'
      }, {
        value: '255.255.255.240',
        name: '16 (255.255.255.240)'
      }, {
        value: '255.255.255.224',
        name: '32 (255.255.255.224)'
      }, {
        value: '255.255.255.192',
        name: '64 (255.255.255.192)'
      }, {
        value: '255.255.255.128',
        name: '128 (255.255.255.128)'
      }, {
        value: '255.255.255.0',
        name: '256 (255.255.255.0)'
      }]);

      _defineProperty(this, "validation", {
        name: [(0, _validators.validatePresence)(true)],
        address: [(0, _validators.validatePresence)(true)],
        netmask: [(0, _validators.validatePresence)(true)]
      });
    }

    // UI Helpers
    get networkStr() {
      return this.netmask === '255.255.255.255' ? this.address : `${this.address} / ${this.netmask}`;
    } // Netmask selectbox list


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "address", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "netmask", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = NetworkModel;
});