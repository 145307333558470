define("frontend2/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Sxo3b4au",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1,[\"embedAccessToken\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n\\n\"],[8,\"ember-notify\",[],[[\"@closeAfter\",\"@messageStyle\"],[4000,\"bootstrap\"]],null]],\"hasEval\":false,\"upvars\":[\"top-nav\",\"apiSession\",\"unless\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "frontend2/templates/application.hbs"
    }
  });

  _exports.default = _default;
});