define("frontend2/components/alert", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <BsModal @onHide={{this.args.onClose}} @open={{this.args.show}} as |modal|>
  <modal.header>
    <h4 class="modal-title"><FaIcon @icon="exclamation-triangle"/> Information</h4>
  </modal.header>
  <modal.body>{{yield}}</modal.body>
  <modal.footer as |footer|>
    <BsButton @onClick={{this.args.onClose}} @type="success">
      <FaIcon @icon="check"/>
      OK
    </BsButton>
  </modal.footer>
  </BsModal>
  
  */
  {
    "id": "RJ7n6JZ7",
    "block": "{\"symbols\":[\"modal\",\"footer\",\"&default\"],\"statements\":[[8,\"bs-modal\",[],[[\"@onHide\",\"@open\"],[[32,0,[\"args\",\"onClose\"]],[32,0,[\"args\",\"show\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"h4\"],[14,0,\"modal-title\"],[12],[8,\"fa-icon\",[],[[\"@icon\"],[\"exclamation-triangle\"]],null],[2,\" Information\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[18,3,null]],\"parameters\":[]}]]],[2,\"\\n\"],[8,[32,1,[\"footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"bs-button\",[],[[\"@onClick\",\"@type\"],[[32,0,[\"args\",\"onClose\"]],\"success\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"check\"]],null],[2,\"\\n    OK\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "frontend2/components/alert.hbs"
    }
  });

  class ConfirmComponent extends _component.default {}

  _exports.default = ConfirmComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ConfirmComponent);
});