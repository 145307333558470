define("frontend2/routes/admin/customers/edit", ["exports", "frontend2/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class AdminCustomersEditRoute extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "controllerName", 'admin/customer');
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.customer = model;
    }

    model(params) {
      // We reload customer with all embedded data to avoid excessive lazy loading
      return this.store.findRecord('customer', params.customer_id, {
        include: 'subscriptions,phone-numbers,currency'
      });
    }

  }

  _exports.default = AdminCustomersEditRoute;
});