define("frontend2/controllers/simple-call-dist", ["exports", "frontend2/controllers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UserController = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class UserController extends _base.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "showAddMemberDlg", _descriptor, this);

      _defineProperty(this, "sipAccountList", []);

      _defineProperty(this, "selectedSipAccount", null);
    }

    // Remove already selected accounts from list
    get availableSipAccounts() {
      return this.sipAccountList.filter(account => {
        return !this.model.members.filter(member => member.get('sipUser.id') === account.id).length;
      });
    }

    openAddMemberDialog() {
      this.showAddMemberDlg = true;
      this.selectedSipAccount = null;
    }

    addMember(item) {
      console.debug(`Adding member ${item.name} to ${this.model.name}`);
      this.store.createRecord('call-group-member', {
        callGroup: this.model,
        type: 1,
        sipUser: item
      });
      this.showAddMemberDlg = false;
    }

    deleteMember(item) {
      console.debug(`Removing member ${item.get('sipUser.name')} from ${this.model.name}`);
      this.model.members.removeObject(item);
      item.deleteRecord();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showAddMemberDlg", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "openAddMemberDialog", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "openAddMemberDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addMember", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "addMember"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteMember", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "deleteMember"), _class.prototype)), _class));
  _exports.default = UserController;
});