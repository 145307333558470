define("frontend2/routes/simple-call-dist/edit", ["exports", "frontend2/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class SimpleCallDistEditRoute extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "controllerName", 'simple-call-dist');
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.sipAccountList = this.store.findAll('sip-trunk');
    }

    model(params) {
      return this.store.findRecord('call-group', params.group_id, {
        include: 'members'
      });
    }

  }

  _exports.default = SimpleCallDistEditRoute;
});