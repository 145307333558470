define("frontend2/templates/admin/customers/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xDKHVF3V",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row align-items-center mt-5 pt-5\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col align-self-center text-center\"],[12],[2,\"\\n      \"],[10,\"h1\"],[14,0,\"text-secondary\"],[12],[8,\"fa-icon\",[],[[\"@icon\",\"@spin\"],[\"spinner\",true]],null],[13],[2,\"\\n        \"],[10,\"h2\"],[12],[2,\"Loading customer data\"],[13],[2,\"\\n        \"],[10,\"p\"],[12],[2,\"Please wait...\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "frontend2/templates/admin/customers/loading.hbs"
    }
  });

  _exports.default = _default;
});