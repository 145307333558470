define("frontend2/controllers/admin/customer", ["exports", "frontend2/controllers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let AdminCustomerController = (_dec = Ember._action, (_class = class AdminCustomerController extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "homeCountryList", []);

      _defineProperty(this, "blacklistList", []);

      _defineProperty(this, "currencyList", []);

      _defineProperty(this, "invoiceTypeList", [{
        value: 0,
        name: 'Invoice via partner'
      }, {
        value: 1,
        name: 'Invoice sent to customer'
      }]);
    }

    get blacklistListWithNone() {
      return [{
        id: null,
        name: '(Do not use blacklist)'
      }].concat(this.blacklistList.toArray());
    }

    loginAsCustomer(customer) {
      this.apiSession.startImpersonating(customer);
      this.transitionToRoute('index');
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "loginAsCustomer", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "loginAsCustomer"), _class.prototype)), _class));
  _exports.default = AdminCustomerController;
});