define("frontend2/components/callflow/base-action", ["exports", "@glimmer/component", "frontend2/models/media-file"], function (_exports, _component, _mediaFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CallflowBaseActionComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = class CallflowBaseActionComponent extends _component.default {
    // Action model
    // State stuff
    // Dropdown lists
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "model", _descriptor2, this);

      _initializerDefineProperty(this, "isCreating", _descriptor3, this);

      _initializerDefineProperty(this, "isCollapsed", _descriptor4, this);

      _initializerDefineProperty(this, "showConfig", _descriptor5, this);

      _initializerDefineProperty(this, "deleteConfirmDialog", _descriptor6, this);

      _initializerDefineProperty(this, "mediaFileList", _descriptor7, this);

      this.model = this.args.info; // If call flow action has needsConfig, open it right away

      if (this.args.info.needsConfig) {
        console.debug('We are creating new action, open config');
        this.isCreating = true;
        this.openConfig();
      }
    }

    toggleCollapsed() {
      this.isCollapsed = !this.isCollapsed;
    }

    openConfig() {
      console.debug('Loading media file list');
      this.mediaFileList = this.store.query('media-file', {
        'type': _mediaFile.MEDIA_FILE_TYPES.CALLFLOW,
        'state': _mediaFile.MEDIA_FILE_STATES.READY
      });
      this.showConfig = true;
    }

    cancelConfig() {
      this.showConfig = false; // If we are creating, pressing cancel will remove action again

      if (this.isCreating) {
        this.args.onDelete(this.args.info);
      }
    }

    saveConfig() {
      console.debug('Applying action config');
      this.showConfig = false;
    }

    applyAction(cs) {
      if (cs) {
        console.debug('Applying action');
        cs.execute();
      }

      this.showConfig = false;
      this.model.needsConfig = false;
    }

    confirmDelete() {
      this.deleteConfirmDialog = true;
    }

    confirmedDelete() {
      this.deleteConfirmDialog = false;
      this.args.onDelete(this.args.info);
    }

    setDragInfo(ev) {
      // Add move operation and id of
      console.debug(`Pickup from position ${this.args.info.position}`);
      const di = JSON.stringify({
        operation: 'move',
        from_position: this.args.info.position
      }); // console.debug(`Setting drag info: ${di}`);

      ev.dataTransfer.setData('application/json', di);

      if (this.args.onDragStateChange) {
        this.args.onDragStateChange(true);
      }
    }

    dragEnd() {
      if (this.args.onDragStateChange) {
        this.args.onDragStateChange(false);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isCreating", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isCollapsed", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showConfig", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "deleteConfirmDialog", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "mediaFileList", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleCollapsed", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "toggleCollapsed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openConfig", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "openConfig"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelConfig", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "cancelConfig"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveConfig", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "saveConfig"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "applyAction", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "applyAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "confirmDelete", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "confirmDelete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "confirmedDelete", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "confirmedDelete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDragInfo", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "setDragInfo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragEnd", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "dragEnd"), _class.prototype)), _class));
  _exports.default = CallflowBaseActionComponent;
});