define("frontend2/routes/link/activate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class LinkActivateRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "controllerName", 'link');
    }

    model(params) {
      // Get token info from server
      return new Promise(resolve => {
        this.apiSession.getTokenInfo(params.key).then(data => {
          resolve(data);
        }).catch(error => {
          if (error._status === 404) {
            this.transitionTo('link.error', 'INVALID');
          } else {
            this.transitionTo('link.error', 'UNKNOWN');
          }
        });
      });
    }

  }

  _exports.default = LinkActivateRoute;
});