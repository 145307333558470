define("frontend2/routes/index", ["exports", "frontend2/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MainIndexRoute extends _base.default {}

  _exports.default = MainIndexRoute;
});