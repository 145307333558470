define("frontend2/routes/integration/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IntegrationIndexRoute extends Ember.Route {}

  _exports.default = IntegrationIndexRoute;
});