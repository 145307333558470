define("frontend2/routes/sip-trunks/edit", ["exports", "frontend2/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class SipTrunksEditRoute extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "controllerName", 'sip-account');
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.phoneNumbers = this.store.findAll('phone-number', {
        include: 'country'
      });
      controller.shortCids = this.store.findAll('short-cid');
      controller.networkList = this.store.findAll('network');
      controller.userList = this.store.findAll('user');
    }

    model(params) {
      return this.store.findRecord('sip-trunk', params.trunk_id);
    }

  }

  _exports.default = SipTrunksEditRoute;
});