define("frontend2/models/peering-group-member", ["exports", "@ember-data/model", "ember-changeset-validations/validators"], function (_exports, _model, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PeeringGroupMemberModel = (_dec = (0, _model.attr)('boolean', {
    defaultValue: true
  }), _dec2 = (0, _model.belongsTo)('customer'), _dec3 = (0, _model.belongsTo)('peering-group'), _dec4 = (0, _model.belongsTo)('price-list'), _dec5 = (0, _model.attr)('number', {
    defaultValue: '0.00'
  }), _dec6 = (0, _model.attr)('number', {
    defaultValue: '0.00'
  }), _dec7 = (0, _model.attr)('number', {
    defaultValue: '0.00'
  }), _dec8 = (0, _model.attr)('number', {
    defaultValue: '0.00'
  }), (_class = class PeeringGroupMemberModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "enabled", _descriptor, this);

      _initializerDefineProperty(this, "customer", _descriptor2, this);

      _initializerDefineProperty(this, "peeringGroup", _descriptor3, this);

      _initializerDefineProperty(this, "priceList", _descriptor4, this);

      _initializerDefineProperty(this, "callAttemptFeeNational", _descriptor5, this);

      _initializerDefineProperty(this, "callAttemptFeeInternational", _descriptor6, this);

      _initializerDefineProperty(this, "callConnectFeeNational", _descriptor7, this);

      _initializerDefineProperty(this, "callConnectFeeInternational", _descriptor8, this);

      _defineProperty(this, "validation", {
        customer: [(0, _validators.validatePresence)(true)],
        peeringGroup: [(0, _validators.validatePresence)(true)],
        priceList: [(0, _validators.validatePresence)(true)]
      });
    }

    // UI Helpers
    get enabledAsStr() {
      return this.enabled ? 'Enabled' : 'Disabled';
    }

    get hasAttemptFee() {
      return this.callAttemptFeeNational || this.callAttemptFeeInternational;
    }

    get hasConnectFee() {
      return this.callConnectFeeNational || this.callConnectFeeInternational;
    }

    get hasNoConnectFee() {
      return !this.hasAttemptFee && !this.hasConnectFee;
    }
    /* VALIDATION RULES */


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "enabled", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "customer", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "peeringGroup", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "priceList", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "callAttemptFeeNational", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "callAttemptFeeInternational", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "callConnectFeeNational", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "callConnectFeeInternational", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PeeringGroupMemberModel;
});