define("frontend2/routes/statistics/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class StatisticsIndexRoute extends Ember.Route {}

  _exports.default = StatisticsIndexRoute;
});