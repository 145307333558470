define("frontend2/validators/number", ["exports", "ember-validators", "ember-changeset-validations/utils/validation-errors", "ember-changeset-validations/utils/with-defaults"], function (_exports, _emberValidators, _validationErrors, _withDefaults) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateNumber;

  function validateNumber(options = {}) {
    let targets;
    options = (0, _withDefaults.default)(options, {
      allowString: true,
      allowNone: false
    });

    if (options.allowBlank) {
      options.allowNone = true;
    }

    if (options && options.on !== undefined) {
      if (typeof options.on === 'string') {
        targets = [options.on];
      } else if (Array.isArray(options.on)) {
        targets = options.on;
      }

      delete options.on;
    }

    return (key, value, _oldValue, changes, content) => {
      if (targets && !targets.some(target => changes[target] || changes[target] === undefined && content[target])) {
        return true;
      }

      let result = (0, _emberValidators.validate)('number', value, options, null, key);
      return result === true ? true : (0, _validationErrors.default)(key, result);
    };
  }
});