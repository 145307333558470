define("frontend2/routes/pricelist/index", ["exports", "frontend2/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class PricelistIndexRoute extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "controllerName", 'price-list');

      _defineProperty(this, "maxResults", 20);

      _defineProperty(this, "queryParams", {
        search: {
          refreshModel: true
        }
      });
    }

    model(params) {
      // Don't search if search is less than 2 characters
      if (!params.search || params.search.length < 3) {
        return [];
      }

      return this.store.query('rate-destination', {
        'search': params.search,
        'page_size': this.maxResults,
        '_endpoint': 'rate-search',
        'include': 'country,price_list'
      });
    }

  }

  _exports.default = PricelistIndexRoute;
});