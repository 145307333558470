define("frontend2/components/playback", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.args.src}}
    <audio controls>
      <source src={{this.args.src}}/>
    </audio>
  {{/if}}
  */
  {
    "id": "IvEDHXGm",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"args\",\"src\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"audio\"],[14,\"controls\",\"\"],[12],[2,\"\\n    \"],[10,\"source\"],[15,\"src\",[32,0,[\"args\",\"src\"]]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "frontend2/components/playback.hbs"
    }
  });

  class PlaybackComponent extends _component.default {}

  _exports.default = PlaybackComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PlaybackComponent);
});