define("frontend2/routes/admin/customers/edit/gateways/index", ["exports", "frontend2/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class AdminCustomersEditGatewaysIndexRoute extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "controllerName", 'admin/customer');
    }

    model() {
      const customer = this.modelFor('admin.customers.edit');
      return this.store.query('peering-group-member', {
        customer: customer.id
      });
    }

  }

  _exports.default = AdminCustomersEditGatewaysIndexRoute;
});