define("frontend2/models/webhook", ["exports", "@ember-data/model", "ember-changeset-validations/validators"], function (_exports, _model, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PAYLOAD_FORMATS = _exports.TYPES = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // Webhook types
  const TYPES = {
    CALL_COMPLETE: 1,
    INCOMING_CALL: 10,
    CALL_COMPLETE_PARTNER: 1000,
    SECURITY_NOTIFICATION_PARTNER: 1100,
    LIMIT_ALERT_PARTNER: 1150,
    PHONE_NUMBER_UPDATE: 2000
  }; // Payload formats

  _exports.TYPES = TYPES;
  const PAYLOAD_FORMATS = {
    JSON: 1,
    SLACK: 2,
    SLACK_API: 3
  };
  _exports.PAYLOAD_FORMATS = PAYLOAD_FORMATS;
  let WebhookModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('boolean', {
    defaultValue: true
  }), _dec3 = (0, _model.attr)('number', {
    defaultValue: null
  }), _dec4 = (0, _model.attr)('number', {
    defaultValue: PAYLOAD_FORMATS.JSON
  }), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('number'), (_class = class WebhookModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "enabled", _descriptor2, this);

      _initializerDefineProperty(this, "type", _descriptor3, this);

      _initializerDefineProperty(this, "payloadFormat", _descriptor4, this);

      _initializerDefineProperty(this, "url", _descriptor5, this);

      _initializerDefineProperty(this, "headerName", _descriptor6, this);

      _initializerDefineProperty(this, "headerValue", _descriptor7, this);

      _initializerDefineProperty(this, "eventCollateTime", _descriptor8, this);

      _initializerDefineProperty(this, "channelName", _descriptor9, this);

      _initializerDefineProperty(this, "authToken", _descriptor10, this);

      _initializerDefineProperty(this, "failedCount", _descriptor11, this);

      _defineProperty(this, "typeList", [{
        value: TYPES.CALL_COMPLETE,
        name: 'Call complete'
      }, {
        value: TYPES.INCOMING_CALL,
        name: 'Incoming call'
      }, {
        value: TYPES.CALL_COMPLETE_PARTNER,
        name: 'Call complete customer (partner)'
      }, {
        value: TYPES.SECURITY_NOTIFICATION_PARTNER,
        name: 'Security notifications (partner)'
      }, {
        value: TYPES.LIMIT_ALERT_PARTNER,
        name: 'Limit alerts (partner)'
      }, {
        value: TYPES.PHONE_NUMBER_UPDATE,
        name: 'Phone number updated (partner)'
      }]);

      _defineProperty(this, "payloadFormatList", [{
        value: PAYLOAD_FORMATS.JSON,
        name: 'JSON'
      }, // {value: PAYLOAD_FORMATS.SLACK, name: 'Slack (deprecated)'},
      {
        value: PAYLOAD_FORMATS.SLACK_API,
        name: 'Slack API'
      }]);

      _defineProperty(this, "validation", {
        name: [(0, _validators.validatePresence)(true)],
        type: [(0, _validators.validatePresence)(true)],
        url: [(0, _validators.validatePresence)(true)]
      });
    }

    // UI Helpers
    get typeStr() {
      switch (this.type) {
        case TYPES.CALL_COMPLETE:
          return 'Call complete';

        case TYPES.INCOMING_CALL:
          return 'Incoming call';

        case TYPES.CALL_COMPLETE_PARTNER:
          return 'Call complete (partner)';

        case TYPES.SECURITY_NOTIFICATION_PARTNER:
          return 'Security notification (partner)';

        case TYPES.LIMIT_ALERT_PARTNER:
          return 'Limit alert (partner)';

        case TYPES.PHONE_NUMBER_UPDATE:
          return 'Phone number updated (partner)';

        default:
          return 'Unknown type';
      }
    }

    get isSlackAPI() {
      return this.payloadFormat === PAYLOAD_FORMATS.SLACK_API;
    }

    get payloadFormatStr() {
      switch (this.payloadFormat) {
        case PAYLOAD_FORMATS.JSON:
          return 'JSON';
        // case PAYLOAD_FORMATS.SLACK:
        //   return 'Slack';

        case PAYLOAD_FORMATS.SLACK_API:
          return 'Slack API';

        default:
          return 'Unknown format';
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "enabled", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "payloadFormat", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "headerName", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "headerValue", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "eventCollateTime", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "channelName", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "authToken", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "failedCount", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = WebhookModel;
});