define("frontend2/routes/admin/customers/edit/gateways/edit", ["exports", "frontend2/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class AdminCustomersEditGatewaysEditRoute extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "controllerName", 'admin/customer');
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.set('peeringGroupList', this.store.findAll('peering-group'));
      controller.set('priceLists', this.store.findAll('price-list'));
    }

    model(params) {
      return this.store.findRecord('peering-group-member', params.member_id);
    }

  }

  _exports.default = AdminCustomersEditGatewaysEditRoute;
});