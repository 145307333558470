define("frontend2/controllers/call-flow", ["exports", "frontend2/controllers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CallFlowController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class CallFlowController extends _base.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "masterDragState", _descriptor, this);

      _initializerDefineProperty(this, "showNameDialog", _descriptor2, this);
    }

    async saveCallFlow(cs, route) {
      console.info(`Saving everything in call flow ${cs.name}`); // First merge call flow name;

      cs.execute(); // Then process all actions

      try {
        await this.model.recursiveSave();
      } catch (e) {
        console.error('--- SAVE ERROR: ---');
        console.error(e);
        this.notify.error('Save of call flow failed, please review');
        return;
      }

      this.notify.success('Call flow saved successfully');
      this.transitionToRoute(route);
    }

    async saveCallFlowName(cs) {
      console.info(`Setting call flow name ${cs.name}`); // First merge call flow name;

      cs.execute(); // Then close dialog

      this.showNameDialog = false;
    }

    setDragState(state) {
      console.debug(`Set master drag state to ${state}`);
      this.masterDragState = state;
    }

    showCallFlowDialog() {
      this.showNameDialog = true;
    }

    closeCallFlowDialog() {
      this.showNameDialog = false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "masterDragState", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showNameDialog", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "saveCallFlow", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "saveCallFlow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveCallFlowName", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "saveCallFlowName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDragState", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setDragState"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showCallFlowDialog", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "showCallFlowDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeCallFlowDialog", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "closeCallFlowDialog"), _class.prototype)), _class));
  _exports.default = CallFlowController;
});