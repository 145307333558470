define("frontend2/models/call-flow-action-hangup", ["exports", "frontend2/models/call-flow-action"], function (_exports, _callFlowAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CallFlowActionHangupModel extends _callFlowAction.default {}

  _exports.default = CallFlowActionHangupModel;
});