define("frontend2/routes/call-routing/add-virtual", ["exports", "frontend2/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class CallRoutingAddVirtualRoute extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "controllerName", 'call-routing');
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.countryList = this.store.findAll('country'); // Set default selection to our home country

      controller.model.country = this.apiSession.current.customer.get('homeCountry');
    }

    model()
    /* params */
    {
      return this.store.createRecord('phone-number');
    }

  }

  _exports.default = CallRoutingAddVirtualRoute;
});