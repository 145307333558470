define("frontend2/controllers/login", ["exports", "frontend2/controllers/base", "ember-changeset-validations/validators"], function (_exports, _base, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UserController = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class UserController extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "identification", '');

      _defineProperty(this, "password", '');

      _initializerDefineProperty(this, "requestPasswordCompleteDlg", _descriptor, this);

      _defineProperty(this, "email", '');

      _defineProperty(this, "recoverValidation", {
        email: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
          type: 'email'
        })]
      });

      _defineProperty(this, "loginValidation", {
        identification: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
          type: 'email'
        })],
        password: [(0, _validators.validatePresence)(true)]
      });
    }

    gotoLoginPage() {
      this.transitionToRoute('login');
    }

    login(cs) {
      this.apiSession.login(cs.identification, cs.password).then(() => {
        this.notify.success('Successfully logged in');
        this.transitionToRoute('index');
      }).catch(err => {
        cs.addError('password', err);
      });
    } // Request new password email


    requestPassword(cs) {
      this.apiSession.reqPasswordRecover(cs.email).then(() => {
        console.log('Password recover ok');
        this.requestPasswordCompleteDlg = true;
      }).catch(err => {
        console.error(err);
      });
    }

    requestPasswordDone() {
      this.requestPasswordCompleteDlg = false;
      this.transitionToRoute('login');
    } // Recover password form validation


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "requestPasswordCompleteDlg", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "gotoLoginPage", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "gotoLoginPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "login", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "login"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "requestPassword", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "requestPassword"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "requestPasswordDone", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "requestPasswordDone"), _class.prototype)), _class));
  _exports.default = UserController;
});