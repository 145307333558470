define("frontend2/components/validation-error", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{message}}
  */
  {
    "id": "wi4I2Di6",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]]],\"hasEval\":false,\"upvars\":[\"message\"]}",
    "meta": {
      "moduleName": "frontend2/components/validation-error.hbs"
    }
  });

  class ValidationErrorComponent extends _component.default {
    get message() {
      if (this.args.errors) {
        const err = this.args.errors.filter(item => item.key === this.args.property);

        if (err.length) {
          console.debug(`Property "${this.args.property}" has ${err.length} validation errors`); // console.debug(err);

          return err[0].validation[0];
        }
      }

      return '';
    }

  }

  _exports.default = ValidationErrorComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ValidationErrorComponent);
});