define("frontend2/routes/call-routing/edit", ["exports", "frontend2/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class CallRoutingEditRoute extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "controllerName", 'call-routing');
    }

    setupController(controller, model) {
      super.setupController(controller, model); // Get sip connections for select box

      controller.sipAccountList = this.store.findAll('sip-trunk');
      controller.callGroupList = this.store.findAll('call-group'); // Create call flow if needed

      if (!model.callFlow) {
        model.callFlow = this.store.createRecord('call-flow', {
          name: `CF_PN_${model.get('country.dial')}${model.startNational}`,
          template: false
        });
        console.debug(`Created new call flow: ${model.callFlow.name}`);
      }
    }

    model(params) {
      return this.store.findRecord('phone-number', params.number_id, {
        reload: true,
        include: 'call_flow'
      });
    }

  }

  _exports.default = CallRoutingEditRoute;
});