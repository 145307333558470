define("frontend2/templates/link/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QPCUXyf6",
    "block": "{\"symbols\":[],\"statements\":[[3,\" Page Header start \"],[2,\"\\n\"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"page-header\"],[12],[2,\"\\n        \"],[10,\"h2\"],[12],[8,\"fa-icon\",[],[[\"@icon\"],[\"exclamation-triangle\"]],null],[2,\" Error\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[3,\" Page Header end \"],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"jumbotron\"],[12],[2,\"\\n\"],[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"h2\"],[12],[2,\"Unknown error\"],[13],[2,\"\\n          \"],[10,\"p\"],[12],[2,\"Unable to process the request, because of an unknown error.\"],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[2,\"\\n              If problem persists, contact the administrator to resolve the problem.\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"h2\"],[12],[2,\"Invalid token\"],[13],[2,\"\\n          \"],[10,\"p\"],[12],[2,\"Unable to process the request, because the token is invalid or expired. Please check the link is complete\\n              and make sure it's correct.\"],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[2,\"\\n              If problem persists, contact the administrator to resolve the problem.\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"errUnknown\",\"if\",\"errInvalid\"]}",
    "meta": {
      "moduleName": "frontend2/templates/link/error.hbs"
    }
  });

  _exports.default = _default;
});