define("frontend2/models/sip-trunk", ["exports", "@ember-data/model", "ember-changeset-validations/validators", "frontend2/validators/number"], function (_exports, _model, _validators, _number) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ACCOUNT_TYPES = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const ACCOUNT_TYPES = {
    TRUNK: 1,
    USER: 3,
    FLEX: 4
  };
  _exports.ACCOUNT_TYPES = ACCOUNT_TYPES;
  let SipTrunkModel = (_dec = (0, _model.attr)('number'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('boolean', {
    defaultValue: true
  }), _dec4 = (0, _model.belongsTo)('user'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('number', {
    defaultValue: 0
  }), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec10 = (0, _model.belongsTo)('phone-number'), _dec11 = (0, _model.belongsTo)('short-cid'), _dec12 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec13 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec14 = (0, _model.hasMany)('network'), _dec15 = (0, _model.attr)('number', {
    defaultValue: 1
  }), _dec16 = (0, _model.attr)('number', {
    defaultValue: 1
  }), _dec17 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec18 = (0, _model.attr)('number', {
    defaultValue: 0
  }), _dec19 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec20 = (0, _model.attr)('number', {
    defaultValue: 7
  }), _dec21 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec22 = (0, _model.attr)('string'), _dec23 = (0, _model.attr)('number', {
    defaultValue: 5060
  }), (_class = class SipTrunkModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "type", _descriptor, this);

      _initializerDefineProperty(this, "name", _descriptor2, this);

      _initializerDefineProperty(this, "enabled", _descriptor3, this);

      _initializerDefineProperty(this, "user", _descriptor4, this);

      _initializerDefineProperty(this, "username", _descriptor5, this);

      _initializerDefineProperty(this, "password", _descriptor6, this);

      _initializerDefineProperty(this, "maxChannels", _descriptor7, this);

      _initializerDefineProperty(this, "flexIdentification", _descriptor8, this);

      _initializerDefineProperty(this, "cidForceStatic", _descriptor9, this);

      _initializerDefineProperty(this, "cidNumber", _descriptor10, this);

      _initializerDefineProperty(this, "shortCid", _descriptor11, this);

      _initializerDefineProperty(this, "cidAlwaysHide", _descriptor12, this);

      _initializerDefineProperty(this, "restrictNetwork", _descriptor13, this);

      _initializerDefineProperty(this, "allowedNetworks", _descriptor14, this);

      _initializerDefineProperty(this, "earlyMedia", _descriptor15, this);

      _initializerDefineProperty(this, "outboundPermissions", _descriptor16, this);

      _initializerDefineProperty(this, "excludeStatistics", _descriptor17, this);

      _initializerDefineProperty(this, "statusViaMedia", _descriptor18, this);

      _initializerDefineProperty(this, "recordCalls", _descriptor19, this);

      _initializerDefineProperty(this, "recordRetentionDays", _descriptor20, this);

      _initializerDefineProperty(this, "useStaticLocation", _descriptor21, this);

      _initializerDefineProperty(this, "staticLocationIpAddress", _descriptor22, this);

      _initializerDefineProperty(this, "staticLocationPort", _descriptor23, this);

      _defineProperty(this, "earlyMediaOptions", [{
        value: 1,
        name: 'Use early media'
      }, {
        value: 2,
        name: 'Instant ring tone'
      }, {
        value: 3,
        name: 'Ignore early media'
      }, {
        value: 4,
        name: 'Minimal ring tone (heart beat)'
      }]);

      _defineProperty(this, "outboundPermissionOptions", [{
        value: 1,
        name: 'Allow call to national and international numbers'
      }, {
        value: 2,
        name: 'Only allow national calls'
      }, {
        value: 3,
        name: 'Block all outbound calls'
      }]);

      _defineProperty(this, "signalingMethodOptions", [{
        value: 0,
        name: 'No status, use signaling only'
      }, {
        value: 1,
        name: 'Give status via media (audio network tones)'
      }]);

      _defineProperty(this, "validation", {
        name: [(0, _validators.validatePresence)(true)],
        recordRetentionDays: [(0, _validators.validatePresence)({
          presence: true,
          on: 'recordCalls'
        }), (0, _number.default)({
          on: 'recordCalls'
        })],
        staticLocationIpAddress: [(0, _validators.validatePresence)({
          presence: true,
          on: 'useStaticLocation'
        })],
        staticLocationPort: [(0, _validators.validatePresence)({
          presence: true,
          on: 'useStaticLocation'
        }), (0, _number.default)({
          on: 'useStaticLocation'
        })],
        maxChannels: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)()]
      });
    }

    /*
     * UI Helpers
     */
    get isTrunkType() {
      return this.type === ACCOUNT_TYPES.TRUNK;
    }

    get isUserType() {
      return this.type === ACCOUNT_TYPES.USER;
    }

    get isFlexType() {
      return this.type === ACCOUNT_TYPES.FLEX;
    }

    get getAccountTypeStr() {
      switch (this.type) {
        case ACCOUNT_TYPES.TRUNK:
          return 'sip trunk';

        case ACCOUNT_TYPES.USER:
          return 'sip user';

        case ACCOUNT_TYPES.FLEX:
          return 'sip flex';

        default:
          return '?? Unknown type';
      }
    } // Option lists


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "type", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "enabled", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "username", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "password", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "maxChannels", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "flexIdentification", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "cidForceStatic", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "cidNumber", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "shortCid", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "cidAlwaysHide", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "restrictNetwork", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "allowedNetworks", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "earlyMedia", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "outboundPermissions", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "excludeStatistics", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "statusViaMedia", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "recordCalls", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "recordRetentionDays", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "useStaticLocation", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "staticLocationIpAddress", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "staticLocationPort", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SipTrunkModel;
});