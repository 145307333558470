define("frontend2/models/media-file", ["exports", "@ember-data/model", "ember-changeset-validations/validators"], function (_exports, _model, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MEDIA_FILE_TYPES = _exports.MEDIA_FILE_STATES = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const MEDIA_FILE_STATES = {
    PROCESSING: 1,
    READY: 2,
    ERROR: 3,
    UNPROCESSED: 4
  };
  _exports.MEDIA_FILE_STATES = MEDIA_FILE_STATES;
  const MEDIA_FILE_TYPES = {
    CALLFLOW: 1,
    VMDROP: 2,
    MUSIC: 3
  };
  _exports.MEDIA_FILE_TYPES = MEDIA_FILE_TYPES;
  let MediaFileModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('number'), _dec6 = (0, _model.attr)('number'), _dec7 = (0, _model.attr)('number', {
    default: MEDIA_FILE_TYPES.CALLFLOW
  }), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), (_class = class MediaFileModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "description", _descriptor2, this);

      _initializerDefineProperty(this, "downloadUrl", _descriptor3, this);

      _initializerDefineProperty(this, "localFilename", _descriptor4, this);

      _initializerDefineProperty(this, "size", _descriptor5, this);

      _initializerDefineProperty(this, "state", _descriptor6, this);

      _initializerDefineProperty(this, "type", _descriptor7, this);

      _initializerDefineProperty(this, "errorMessage", _descriptor8, this);

      _initializerDefineProperty(this, "playbackUrl", _descriptor9, this);

      _defineProperty(this, "typeList", [{
        value: 1,
        name: 'Call flow / Inbound'
      }, {
        value: 2,
        name: 'Voicemail drop'
      }
      /* {value: 3, name: 'Music on hold'} */
      ]);

      _defineProperty(this, "validation", {
        name: [(0, _validators.validatePresence)(true)],
        type: [(0, _validators.validatePresence)({
          presence: true,
          message: 'Please select type of media file'
        })],
        downloadUrl: [(0, _validators.validatePresence)({
          presence: true,
          on: 'isNew',
          message: 'You must upload file first'
        })]
      });
    }

    get stateStr() {
      switch (this.state) {
        case MEDIA_FILE_STATES.PROCESSING:
          return 'Processing';

        case MEDIA_FILE_STATES.READY:
          return 'Active';

        case MEDIA_FILE_STATES.ERROR:
          return 'Error';

        case MEDIA_FILE_STATES.UNPROCESSED:
          return 'Unprocessed';

        default:
          return `Unknown state ${this.state}`;
      }
    }

    get typeStr() {
      switch (this.type) {
        case MEDIA_FILE_TYPES.CALLFLOW:
          return 'Call flow';

        case MEDIA_FILE_TYPES.VMDROP:
          return 'Voicemail drop';

        case MEDIA_FILE_TYPES.MUSIC:
          return 'Music';

        default:
          return `Unknown type ${this.type}`;
      }
    } // States


    get isProcessing() {
      return this.state === MEDIA_FILE_STATES.PROCESSING;
    }

    get isReady() {
      return this.state === MEDIA_FILE_STATES.READY;
    }

    get isErrorState() {
      return this.state === MEDIA_FILE_STATES.ERROR;
    } // Types


    get isCallflowType() {
      return this.type === MEDIA_FILE_TYPES.CALLFLOW;
    }

    get isVMDropType() {
      return this.type === MEDIA_FILE_TYPES.VMDROP;
    }

    get isMusicType() {
      return this.type === MEDIA_FILE_TYPES.MUSIC;
    }
    /* VALIDATION RULES */


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "downloadUrl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "localFilename", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "size", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "state", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "errorMessage", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "playbackUrl", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = MediaFileModel;
});