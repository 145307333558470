define("frontend2/routes/admin/customers/create", ["exports", "frontend2/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class AdminCustomersCreateRoute extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "controllerName", 'admin/customer');
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.blacklistList = this.store.findAll('blacklist');
      controller.homeCountryList = this.store.query('country', {
        'home_country_selectable': 'True'
      });
      controller.currencyList = this.store.findAll('currency');
    }

    model() {
      return this.store.createRecord('customer');
    }

  }

  _exports.default = AdminCustomersCreateRoute;
});