define("frontend2/controllers/user", ["exports", "frontend2/controllers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UserController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class UserController extends _base.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "wasInvitedDialog", _descriptor, this);

      _initializerDefineProperty(this, "wasReinvitedDialog", _descriptor2, this);

      _defineProperty(this, "userTypeList", []);
    }

    get userTypeFilteredList() {
      return this.userTypeList.filter(type => type.level <= this.apiSession.current.get('user.type.level'));
    }

    async postSaveHook(creating) {
      // We need to send invitation for new users
      // console.log(this.model);
      if (creating) {
        this.wasInvitedDialog = true;
        return new Promise(resolve => {
          this.apiSession.inviteUser(this.model).then(() => {
            console.debug('Invitation sent successfully');
            resolve(false);
          }).catch(err => {
            console.error('Invitation failed:');
            console.error(err);
          });
        });
      } else {
        console.debug('Skipping invitation for active user');
        return Promise.resolve(true);
      }
    } // INVITATION DIALOG


    closeInviteDialog() {
      this.wasInvitedDialog = false;
      this.transitionToRoute('users');
    } // REINVITATION DIALOG


    reinviteUser() {
      this.apiSession.inviteUser(this.model).then(() => {
        this.wasReinvitedDialog = true;
      });
    }

    closeReinviteDialog() {
      this.wasReinvitedDialog = false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "wasInvitedDialog", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "wasReinvitedDialog", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "closeInviteDialog", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "closeInviteDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reinviteUser", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "reinviteUser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeReinviteDialog", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "closeReinviteDialog"), _class.prototype)), _class));
  _exports.default = UserController;
});