define("frontend2/routes/integration/webhooks/queue", ["exports", "frontend2/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class IntegrationWebhooksQueueRoute extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "controllerName", 'webhook');
    }

    model(params) {
      return this.store.query('webhook-queue', {
        webhook_id: params.webhook_id
      });
    }

  }

  _exports.default = IntegrationWebhooksQueueRoute;
});