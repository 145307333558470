define("frontend2/components/media-upload", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#file-upload name="media" accept="audio/*" multiple=false onfileadd=(action "uploadFile") as |queue|}}
    {{#if signedURL}}
      <!-- Uploading in progress or done (file selected) -->
      {{#if fileInfo}}
        {{#unless uploadLocation}}
          {{#if uploadErrorMsg}}
            <!-- Something went wrong! -->
            {{uploadErrorMsg}}
          {{else}}
            <!-- Upload in progress -->
            Uploading '{{fileInfo.name}}'
            ({{format-number fileInfo.kbsize}} KB) in progress...
          {{/if}}
        {{else}}
          <!-- Finished uploading -->
          The file "{{fileInfo.name}}" was uploaded successfully
        {{/unless}}
      {{else}}
        <!-- Waiting for file selection -->
        {{yield}}
      {{/if}}
    {{else}}
      <!-- Must wait for API to returned signed upload url -->
      Waiting for upload server...
    {{/if}}
  {{/file-upload}}
  
  */
  {
    "id": "ojpxDMLk",
    "block": "{\"symbols\":[\"queue\",\"&default\"],\"statements\":[[6,[37,8],null,[[\"name\",\"accept\",\"multiple\",\"onfileadd\"],[\"media\",\"audio/*\",false,[30,[36,7],[[32,0],\"uploadFile\"],null]]],[[\"default\"],[{\"statements\":[[6,[37,3],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[3,\" Uploading in progress or done (file selected) \"],[2,\"\\n\"],[6,[37,3],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[3,\" Something went wrong! \"],[2,\"\\n          \"],[1,[34,2]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[3,\" Upload in progress \"],[2,\"\\n          Uploading '\"],[1,[35,0,[\"name\"]]],[2,\"'\\n          (\"],[1,[30,[36,1],[[35,0,[\"kbsize\"]]],null]],[2,\" KB) in progress...\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[3,\" Finished uploading \"],[2,\"\\n        The file \\\"\"],[1,[35,0,[\"name\"]]],[2,\"\\\" was uploaded successfully\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[3,\" Waiting for file selection \"],[2,\"\\n      \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[3,\" Must wait for API to returned signed upload url \"],[2,\"\\n    Waiting for upload server...\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"fileInfo\",\"format-number\",\"uploadErrorMsg\",\"if\",\"uploadLocation\",\"unless\",\"signedURL\",\"action\",\"file-upload\"]}",
    "meta": {
      "moduleName": "frontend2/components/media-upload.hbs"
    }
  });

  let MediaUploadComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, (_class = class MediaUploadComponent extends _component.default {
    constructor() {
      super(...arguments); // Get signed URL to upload file

      _initializerDefineProperty(this, "apiSession", _descriptor, this);

      _initializerDefineProperty(this, "signedURL", _descriptor2, this);

      _initializerDefineProperty(this, "uploadLocation", _descriptor3, this);

      _initializerDefineProperty(this, "fileInfo", _descriptor4, this);

      _initializerDefineProperty(this, "uploadErrorMsg", _descriptor5, this);

      this.apiSession.getSignedUploadURL().then(result => {
        this.signedURL = result;
      });
    }

    uploadFile(file) {
      this.uploadErrorMsg = '';
      console.info(`Uploading file: ${file.blob.name}`);
      this.fileInfo = file.blob;
      this.fileInfo.kbsize = file.blob.size / 1024; // Fire event when starting

      if (this.args.onUploadBegin) {
        this.args.onUploadBegin();
      } // Begin file upload


      file.upload(this.signedURL.url, {
        data: this.signedURL.credentials
      }).then(res => {
        console.debug(`Upload complete to: ${res.headers.location}`);

        if (this.args.onUploadComplete) {
          this.uploadLocation = res.headers.location;

          if (this.args.onUploadComplete) {
            this.args.onUploadComplete(this.fileInfo.name, this.uploadLocation);
          }

          if (this.args.validate) {
            this.args.validate.validate();
          }
        }
      }, err => {
        console.error('Upload error:');
        console.error(err);
        this.uploadErrorMsg = `'Unable to upload file, status ${err.status}`;

        if (this.args.validate) {
          this.args.validate.validate();
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "apiSession", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "signedURL", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uploadLocation", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fileInfo", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uploadErrorMsg", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "uploadFile", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "uploadFile"), _class.prototype)), _class));
  _exports.default = MediaUploadComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, MediaUploadComponent);
});