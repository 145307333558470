define("frontend2/authenticators/oauth2", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "frontend2/config/environment"], function (_exports, _oauth2PasswordGrant, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class OAuth2Authenticator extends _oauth2PasswordGrant.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "serverTokenEndpoint", _environment.default.APP.API_HOST + '/api/o/token/');

      _defineProperty(this, "clientId", _environment.default.APP.API_CLIENT_ID);
    }

  }

  _exports.default = OAuth2Authenticator;
});