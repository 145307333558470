define("frontend2/routes/base", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let BaseRoute = (_dec = Ember._action, (_class = class BaseRoute extends Ember.Route {
    // Handles cleaning up of dirty models
    willTransition() {
      const model = this.controllerFor(this.routeName).model;

      if (model instanceof _model.default) {
        if (model.isNew && !model.isSaving) {
          console.debug('Cleaning up unused model');
          model.rollbackAttributes();
        } // Cleanup "has many" relations


        model.eachRelationship(function (name, relationship) {
          if (relationship.kind === 'hasMany') {
            let rollbackList = [];
            console.debug(`Check dirty relations in ${name}`); // We build a complete list of objects that needs to be rolled back first
            // (Or else list will get shorter while we perform the rollback)

            model[name].forEach(function (item) {
              if (item.isNew || item.hasDirtyAttributes && !item.isSaving) {
                rollbackList.push(item);
              }
            }); // Then we rollback records

            rollbackList.forEach(function (item) {
              item.rollbackAttributes();
            });
          }
        });
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "willTransition", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype)), _class));
  _exports.default = BaseRoute;
});