define("frontend2/router", ["exports", "frontend2/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('login');
    this.route('recover-password');
    this.route('embed-error');
    this.route('admin', function () {
      this.route('customers', function () {
        this.route('create');
        this.route('edit', {
          path: ':customer_id'
        }, function () {
          this.route('gateways', function () {
            this.route('edit', {
              path: ':member_id'
            });
            this.route('create');
          });
          this.route('phone-numbers', function () {
            this.route('edit', {
              path: ':number_id'
            });
            this.route('create');
          });
          this.route('subscriptions', function () {
            this.route('edit', {
              path: ':subscription_id'
            });
            this.route('create');
          });
        });
      });
      this.route('peering', function () {
        this.route('gateways', function () {
          this.route('edit');
          this.route('create');
        });
        this.route('groups', function () {
          this.route('edit');
          this.route('create');
        });
      });
      this.route('pricelists', function () {
        this.route('edit');
      });
    });
    this.route('users', function () {
      this.route('edit', {
        path: ':user_id'
      });
      this.route('create');
    });
    this.route('sip-trunks', function () {
      this.route('edit', {
        path: ':trunk_id'
      });
      this.route('create', {
        path: 'create/:type'
      });
      this.route('create-for-user', {
        path: 'create-for-user/:user_id'
      });
    });
    this.route('short-cid', function () {
      this.route('edit', {
        path: ':cid_id'
      });
      this.route('create');
    });
    this.route('simple-call-dist', function () {
      this.route('edit', {
        path: ':group_id'
      });
      this.route('create');
    });
    this.route('security', function () {
      this.route('networks', function () {
        this.route('edit', {
          path: ':network_id'
        });
        this.route('create');
      });
    });
    this.route('statistics', function () {
      this.route('cid', function () {
        this.route('index', {
          path: ':interval/:start_ts'
        });
      });
      this.route('cost', function () {});
      this.route('outbound', function () {});
      this.route('inbound', function () {});
    });
    this.route('call-details', function () {
      this.route('info', {
        path: ':call_id'
      });
    });
    this.route('pricelist', function () {});
    this.route('call-routing', function () {
      this.route('edit', {
        path: ':number_id'
      });
      this.route('add-virtual');
      this.route('verify', {
        path: 'verify/:number_id'
      });
    });
    this.route('ledger', function () {});
    this.route('integration', function () {
      this.route('access-tokens', function () {});
      this.route('webhooks', function () {
        this.route('edit', {
          path: ':webhook_id'
        });
        this.route('create');
        this.route('queue', {
          path: ':webhook_id/queue'
        });
      });
    });
    this.route('media', function () {
      this.route('edit', {
        path: ':media_id'
      });
      this.route('create');
    });
    this.route('link', function () {
      this.route('activate', {
        path: 'activate/:key'
      });
      this.route('error', {
        path: 'error/:error_enum'
      });
      this.route('recover', {
        path: 'recover/:key'
      });
    });
    this.route('call-flow', function () {
      this.route('edit', {
        path: ':call_flow_id'
      });
      this.route('create');
    });
  });
});