define("frontend2/routes/simple-call-dist/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class SimpleCallDistIndexRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "controllerName", 'simple-call-dist');
    }

    model() {
      return this.store.findAll('call-group', {
        include: 'members'
      });
    }

  }

  _exports.default = SimpleCallDistIndexRoute;
});