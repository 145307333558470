define("frontend2/models/customer", ["exports", "@ember-data/model", "ember-changeset-validations/validators", "frontend2/models/aggregated-customer-stat"], function (_exports, _model, _validators, _aggregatedCustomerStat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CUSTOMER_TYPES = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const CUSTOMER_TYPES = {
    NORMAL: 1,
    PARTNER: 2,
    PARTNER_CUSTOMER: 3
  };
  _exports.CUSTOMER_TYPES = CUSTOMER_TYPES;
  let CustomerModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('number', {
    defaultValue: 1
  }), _dec4 = (0, _model.belongsTo)('country'), _dec5 = (0, _model.belongsTo)('currency'), _dec6 = (0, _model.belongsTo)('blacklist'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.hasMany)('customer-subscription'), _dec9 = (0, _model.hasMany)('partner-phone-number'), _dec10 = (0, _model.hasMany)('peering-group-member'), _dec11 = (0, _model.attr)('number', {
    defaultValue: '-1000'
  }), _dec12 = (0, _model.attr)('number', {
    defaultValue: '3'
  }), _dec13 = (0, _model.attr)('number', {
    defaultValue: '500'
  }), _dec14 = (0, _model.attr)('number', {
    defaultValue: '0.5'
  }), _dec15 = (0, _model.attr)('number', {
    defaultValue: 1
  }), _dec16 = (0, _model.attr)('string', {
    defaultValue: ''
  }), _dec17 = (0, _model.hasMany)('aggregated-customer-stat', {
    async: false
  }), _dec18 = (0, _model.attr)('boolean', {
    defaultValue: true
  }), _dec19 = (0, _model.attr)('boolean', {
    defaultValue: true
  }), _dec20 = (0, _model.attr)('number', {
    defaultValue: 30
  }), _dec21 = (0, _model.attr)('number', {
    defaultValue: 100
  }), _dec22 = (0, _model.attr)('number', {
    defaultValue: 20
  }), (_class = class CustomerModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "shortName", _descriptor2, this);

      _initializerDefineProperty(this, "type", _descriptor3, this);

      _initializerDefineProperty(this, "homeCountry", _descriptor4, this);

      _initializerDefineProperty(this, "currency", _descriptor5, this);

      _initializerDefineProperty(this, "blacklist", _descriptor6, this);

      _initializerDefineProperty(this, "partnerAccountId", _descriptor7, this);

      _initializerDefineProperty(this, "subscriptions", _descriptor8, this);

      _initializerDefineProperty(this, "phoneNumbers", _descriptor9, this);

      _initializerDefineProperty(this, "peeringGroupMembers", _descriptor10, this);

      _initializerDefineProperty(this, "creditMax", _descriptor11, this);

      _initializerDefineProperty(this, "maxRateCost", _descriptor12, this);

      _initializerDefineProperty(this, "maxDailyCost", _descriptor13, this);

      _initializerDefineProperty(this, "maxRateCostPerCall", _descriptor14, this);

      _initializerDefineProperty(this, "invoiceType", _descriptor15, this);

      _initializerDefineProperty(this, "invoiceIdentification", _descriptor16, this);

      _initializerDefineProperty(this, "aggregatedCustomerStat", _descriptor17, this);

      _initializerDefineProperty(this, "blockEeaFromNonEea", _descriptor18, this);

      _initializerDefineProperty(this, "blockAnonymousCli", _descriptor19, this);

      _initializerDefineProperty(this, "maxShortCallsPerSipTrunk", _descriptor20, this);

      _initializerDefineProperty(this, "maxShortCallsTotal", _descriptor21, this);

      _initializerDefineProperty(this, "maxPublicNumberPurchase", _descriptor22, this);

      _defineProperty(this, "validation", {
        name: [(0, _validators.validatePresence)(true)],
        homeCountry: [(0, _validators.validatePresence)(true)],
        currency: [(0, _validators.validatePresence)(true)],
        maxRateCost: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)()],
        maxDailyCost: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)()],
        creditMax: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)()]
      });
    }

    get totalSubscriptionSales() {
      // Iterate through all subscriptions and sum sales value
      let salesValue = 0;
      this.subscriptions.forEach(item => {
        salesValue += item.totalSalesValue;
      });
      return salesValue;
    }

    get stat24Hours() {
      return this.aggregatedCustomerStat.find(i => i.interval === _aggregatedCustomerStat.INTERVAL_TYPES.HOURS24);
    }

    get stat30days() {
      return this.aggregatedCustomerStat.find(i => i.interval === _aggregatedCustomerStat.INTERVAL_TYPES.DAYS30);
    } // Return true if credit max is reached


    get reachedCreditLimit() {
      return this.balance <= this.creditMax;
    } // Return true if balance is 80% or more of credit limit


    get hasCreditWarning() {
      return this.balance <= this.creditMax * 0.8;
    } // UI Helpers


    get typeAsStr() {
      switch (this.type) {
        case CUSTOMER_TYPES.NORMAL:
          return 'Normal';

        case CUSTOMER_TYPES.PARTNER:
          return 'Partner';

        case CUSTOMER_TYPES.PARTNER_CUSTOMER:
          return 'Partner customer';

        default:
          return 'Unknown type';
      }
    }
    /* VALIDATION RULES */


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "shortName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "homeCountry", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currency", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "blacklist", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "partnerAccountId", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "subscriptions", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "phoneNumbers", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "peeringGroupMembers", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "creditMax", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "maxRateCost", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "maxDailyCost", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "maxRateCostPerCall", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "invoiceType", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "invoiceIdentification", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "aggregatedCustomerStat", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "blockEeaFromNonEea", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "blockAnonymousCli", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "maxShortCallsPerSipTrunk", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "maxShortCallsTotal", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "maxPublicNumberPurchase", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CustomerModel;
});