define("frontend2/adapters/application", ["exports", "@ember-data/adapter/json-api", "@ember-data/adapter/error", "frontend2/config/environment"], function (_exports, _jsonApi, _error, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // Translate polymorphic models into parent model (probably a better way to do this)
  const MODEL_TRANSLATION = {
    'call-flow-action-play-msg': 'call-flow-actions',
    'call-flow-action-hangup': 'call-flow-actions',
    'call-flow-action-ivr-menu': 'call-flow-actions',
    'call-flow-action-jump-call-flow': 'call-flow-actions',
    'call-flow-action-call-group': 'call-flow-actions',
    'call-flow-action-call-direct': 'call-flow-actions'
  };
  let ApplicationAdapter = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class ApplicationAdapter extends _jsonApi.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "apiSession", _descriptor2, this);

      _defineProperty(this, "host", _environment.default.APP.API_HOST);

      _defineProperty(this, "namespace", 'api');
    }

    get headers() {
      let headers = {}; // Use embed config token for auth?

      if (this.apiSession.embedAccessToken) {
        console.debug('Auth using embedded access token');
        headers['Authorization'] = `Bearer ${this.apiSession.embedAccessToken}`;
      } else if (this.session.isAuthenticated) {
        headers['Authorization'] = `Bearer ${this.session.data.authenticated.access_token}`; // Impersonate

        const imp_id = localStorage.getItem('impCid');

        if (imp_id) {
          console.debug(`Impersonating customer with id ${imp_id}`);
          headers['X-USE-CUSTOMER-ID'] = imp_id;
        }
      }

      return headers;
    }

    buildURL(modelName, id, record, queryType, query) {
      // If query has '_endpoint' use this as URL and skip normal url build
      if (query && query._endpoint) {
        // Get endpoint and remove from query string
        let ep = query._endpoint;
        delete query._endpoint;
        return `${this.host}/${this.namespace}/${ep}/`;
      } // Translate


      if (modelName in MODEL_TRANSLATION) {
        // console.debug(`Translating model name ${modelName} into ${MODEL_TRANSLATION[modelName]}`);
        modelName = MODEL_TRANSLATION[modelName];
      } // Just use default


      return super.buildURL(modelName, id, record, queryType, query) + '/';
    } // Handle server validation nicely


    handleResponse(status, headers, payload) {
      if (status === 400 && payload.errors) {
        console.info('Adapter error:');
        console.debug(payload.errors);
        return new _error.InvalidError(payload.errors);
      } else {
        return super.handleResponse(...arguments);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "apiSession", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ApplicationAdapter;
});