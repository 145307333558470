define("frontend2/routes/sip-trunks/create-for-user", ["exports", "frontend2/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class SipTrunksCreateForUserRoute extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "controllerName", 'sip-account');

      _defineProperty(this, "templateName", 'sip-trunks.edit');

      _defineProperty(this, "user", null);
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.phoneNumbers = this.store.findAll('phone-number', {
        include: 'country'
      });
      controller.shortCids = this.store.findAll('short-cid');
      controller.networkList = this.store.findAll('network');
      controller.userList = this.store.findAll('user');
    }

    beforeModel() {}

    model(params) {
      const record = this.store.createRecord('sip-trunk');

      if (params.user_id) {
        return new Promise((resolve, reject) => {
          this.store.findRecord('user', params.user_id).then(user => {
            console.debug(`Creating sip user account for ${user.name}`);
            record.type = 3;
            record.user = user;
            resolve(record);
          }).catch(err => {
            console.error('Unable to find user, unable to set user');
            reject(err);
          });
        });
      }

      return record;
    }

  }

  _exports.default = SipTrunksCreateForUserRoute;
});