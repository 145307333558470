define("frontend2/templates/embed-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wi7qMiBT",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"jumbotron\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"\\n      \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"exclamation-triangle\"]],null],[2,\"\\n      Session timed out\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"Unable to process the request, because session timed out.\"],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[2,\"\\n      Please refresh your browser and if problem persists, contact support to resolve the problem.\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "frontend2/templates/embed-error.hbs"
    }
  });

  _exports.default = _default;
});