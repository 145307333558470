define("frontend2/components/confirm", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <BsModal @onHide={{action "cancelConfirm"}} @open={{this.args.show}} as |modal|>
  <modal.header>
    <h4 class="modal-title"><FaIcon @icon="exclamation-triangle"/> Please confirm</h4>
  </modal.header>
  <modal.body>{{yield}}</modal.body>
  <modal.footer as |footer|>
    <BsButton @onClick={{action "confirmed"}} @type="success">
      <FaIcon @icon="trash"/>
      Yes, delete
    </BsButton>
    <BsButton @onClick={{action "cancelConfirm"}} @type="danger">
      <FaIcon @icon="times"/>
      No, abort
    </BsButton>
  </modal.footer>
  </BsModal>
  
  */
  {
    "id": "lGxj+VBZ",
    "block": "{\"symbols\":[\"modal\",\"footer\",\"&default\"],\"statements\":[[8,\"bs-modal\",[],[[\"@onHide\",\"@open\"],[[30,[36,0],[[32,0],\"cancelConfirm\"],null],[32,0,[\"args\",\"show\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"h4\"],[14,0,\"modal-title\"],[12],[8,\"fa-icon\",[],[[\"@icon\"],[\"exclamation-triangle\"]],null],[2,\" Please confirm\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[18,3,null]],\"parameters\":[]}]]],[2,\"\\n\"],[8,[32,1,[\"footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"bs-button\",[],[[\"@onClick\",\"@type\"],[[30,[36,0],[[32,0],\"confirmed\"],null],\"success\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"trash\"]],null],[2,\"\\n    Yes, delete\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"bs-button\",[],[[\"@onClick\",\"@type\"],[[30,[36,0],[[32,0],\"cancelConfirm\"],null],\"danger\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"times\"]],null],[2,\"\\n    No, abort\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "frontend2/components/confirm.hbs"
    }
  });

  let ConfirmComponent = (_dec = Ember._action, _dec2 = Ember._action, (_class = class ConfirmComponent extends _component.default {
    cancelConfirm() {
      this.args.onClose();
    }

    confirmed() {
      this.args.onClose();
      this.args.onConfirm();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "cancelConfirm", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "cancelConfirm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "confirmed", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "confirmed"), _class.prototype)), _class));
  _exports.default = ConfirmComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ConfirmComponent);
});