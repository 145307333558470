define("frontend2/routes/call-details/index", ["exports", "frontend2/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class CallDetailsIndexRoute extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "controllerName", 'call-details');

      _defineProperty(this, "queryParams", {
        search: {
          refreshModel: true
        },
        idd: {
          refreshModel: true
        },
        interval: {
          refreshModel: true
        }
      });
    }

    setupController(controller, model) {
      super.setupController(controller, model);

      if (!controller.countryList) {
        controller.countryList = this.store.findAll('country'); // If no country selected, use customer home country

        if (!controller.idd) {
          controller.idd = this.apiSession.current.customer.get('homeCountry.dial');
        }
      }
    }

    model(params) {
      console.log('Search:');
      console.log(params);

      if (!params.search || params.search.length <= 4 || !params.idd) {
        return [];
      }

      let qStr = {
        'destination_number': params.idd + params.search,
        'page_size': 100
      }; // Search interval ?

      if (params.interval > 0) {
        let intervalDate = new Date(new Date() - 1000 * 3600 * params.interval);
        qStr.start_ts_gte = intervalDate.toISOString();
      }

      return this.store.query('cdr', qStr);
    }

  }

  _exports.default = CallDetailsIndexRoute;
});