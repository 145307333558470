define("frontend2/components/format-duration", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{durationStr}}
  
  */
  {
    "id": "7TYk0B3U",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"durationStr\"]}",
    "meta": {
      "moduleName": "frontend2/components/format-duration.hbs"
    }
  });

  class FormatDurationComponent extends _component.default {
    get durationStr() {
      const sec = this.args.seconds;
      let s = ''; // Hour

      if (sec / 3600 >= 1) {
        s = s + Math.floor(sec / 3600) % 24 + ':';
      } // Seconds + Min


      if (sec >= 0) {
        s = s + ("00" + Math.floor(Math.floor(sec / 60)) % 60).slice(-2) + ':';
        s = s + ("00" + Math.round(sec) % 60).slice(-2);
      } else {
        s = '00:00';
      }

      return s;
    }

  }

  _exports.default = FormatDurationComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormatDurationComponent);
});