define("frontend2/models/phone-number", ["exports", "@ember-data/model", "ember-changeset-validations/validators"], function (_exports, _model, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TYPES = _exports.ROUTE_ACTIONS = _exports.STATES = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // Phone number states
  const STATES = {
    ACTIVE: 1,
    TERMINATING: 2,
    INACTIVE: 3,
    DISABLED: 4,
    UNVERIFIED: 5,
    CREATING: 6,
    PENDING_ACTIVATION: 7
  }; // Route actions enum

  _exports.STATES = STATES;
  const ROUTE_ACTIONS = {
    NOTHING: 0,
    ROUTE_TRUNK: 1,
    // TRANSFER: 2,
    // HANGUP: 4,
    // CALL_GROUP: 5,
    CALL_FLOW: 6
  }; // Phone number types enum

  _exports.ROUTE_ACTIONS = ROUTE_ACTIONS;
  const TYPES = {
    VIRTUAL: 1,
    PUBLIC: 2
  };
  _exports.TYPES = TYPES;
  let PhoneNumberModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('number'), _dec3 = (0, _model.belongsTo)('country'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('number', {
    defaultValue: TYPES.VIRTUAL
  }), _dec6 = (0, _model.attr)('number', {
    defaultValue: ROUTE_ACTIONS.NOTHING
  }), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.belongsTo)('sip-trunk', {
    inverse: null
  }), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.belongsTo)('call-flow', {
    async: false
  }), _dec11 = (0, _model.attr)('number'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('String'), (_class = class PhoneNumberModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "state", _descriptor2, this);

      _initializerDefineProperty(this, "country", _descriptor3, this);

      _initializerDefineProperty(this, "startNational", _descriptor4, this);

      _initializerDefineProperty(this, "type", _descriptor5, this);

      _initializerDefineProperty(this, "routeAction", _descriptor6, this);

      _initializerDefineProperty(this, "routeActionParam", _descriptor7, this);

      _initializerDefineProperty(this, "destinationSipTrunk", _descriptor8, this);

      _initializerDefineProperty(this, "transferNumber", _descriptor9, this);

      _initializerDefineProperty(this, "callFlow", _descriptor10, this);

      _initializerDefineProperty(this, "verifyAttempts", _descriptor11, this);

      _initializerDefineProperty(this, "verifyPin", _descriptor12, this);

      _initializerDefineProperty(this, "verifyErrorMsg", _descriptor13, this);

      _defineProperty(this, "routeActionList", [{
        value: ROUTE_ACTIONS.NOTHING,
        label: 'Do nothing'
      }, {
        value: ROUTE_ACTIONS.ROUTE_TRUNK,
        label: 'Route to sip account'
      }, // {value: ROUTE_ACTIONS.TRANSFER, label: 'Transfer call to different number'},
      // {value: ROUTE_ACTIONS.CALL_GROUP, label: 'Route to call group'},
      {
        value: ROUTE_ACTIONS.CALL_FLOW,
        label: 'Execute call flow'
      }]);

      _defineProperty(this, "numberTypeList", [{
        value: TYPES.VIRTUAL,
        name: 'Virtual'
      }, {
        value: TYPES.PUBLIC,
        name: 'Public'
      }]);

      _defineProperty(this, "stateList", [{
        value: STATES.PENDING_ACTIVATION,
        name: 'Pending activation'
      }, {
        value: STATES.CREATING,
        name: 'Creating'
      }, {
        value: STATES.UNVERIFIED,
        name: 'Unverified'
      }, {
        value: STATES.ACTIVE,
        name: 'Active'
      }, {
        value: STATES.TERMINATING,
        name: 'Terminating'
      }, {
        value: STATES.DISABLED,
        name: 'Disabled'
      }, {
        value: STATES.INACTIVE,
        name: 'Inactive'
      }]);

      _defineProperty(this, "validation", {
        name: [(0, _validators.validatePresence)(true)],
        country: [(0, _validators.validatePresence)(true)],
        startNational: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)()]
      });
    }

    // UI Helpers    // Label string for select box
    get labelStr() {
      return `(+${this.country.get('dial')}) ${this.startNational} [${this.name}]`;
    }

    get fullNumber() {
      return `(+${this.country.get('dial')}) ${this.startNational}`;
    }

    get typeStr() {
      switch (this.type) {
        case 1:
          return 'Virtual';

        case 2:
          return 'Public';

        default:
          return 'Unknown type';
      }
    }

    get routeActionStr() {
      switch (this.routeAction) {
        case ROUTE_ACTIONS.NOTHING:
          return 'Do nothing';

        case ROUTE_ACTIONS.ROUTE_TRUNK:
          return 'Route to sip account: ' + this.destinationSipTrunk.get('name');

        case ROUTE_ACTIONS.TRANSFER:
          return 'Transfer call to: +' + this.transferNumber;

        case ROUTE_ACTIONS.CALL_GROUP:
          return 'Route to call group';

        case ROUTE_ACTIONS.CALL_FLOW:
          return 'Execute call flow';

        default:
          return 'Unknown action';
      }
    } // Phone number state helpers


    get isAvailable() {
      return this.state === STATES.ACTIVE || this.state === STATES.TERMINATING;
    }

    get isExternal() {
      return this.type === TYPES.PUBLIC;
    }

    get isActive() {
      return this.state === STATES.ACTIVE;
    }

    get isTerminating() {
      return this.state === STATES.TERMINATING;
    }

    get isInactive() {
      return this.state === STATES.INACTIVE;
    }

    get isDisabled() {
      return this.state === STATES.DISABLED;
    }

    get isUnverified() {
      return this.state === STATES.UNVERIFIED || this.state === STATES.UNVERIFIED;
    }

    get isActiveVirtual() {
      return this.isActive && this.type === TYPES.VIRTUAL;
    }

    get isActivePublic() {
      return this.isActive && this.type === TYPES.PUBLIC;
    }
    /* VALIDATION RULES */


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "state", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "country", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "startNational", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "routeAction", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "routeActionParam", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "destinationSipTrunk", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "transferNumber", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "callFlow", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "verifyAttempts", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "verifyPin", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "verifyErrorMsg", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PhoneNumberModel;
});