define("frontend2/routes/call-flow/edit", ["exports", "frontend2/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let CallFlowEditRoute = (_dec = Ember._action, (_class = class CallFlowEditRoute extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "controllerName", 'call-flow');
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.showNameDialog = false;
    }

    model(params) {
      // Fetch call flow with actions. We must reload because related item may not be loaded and it's required
      // return this.store.findRecord('call-flow', params.call_flow_id, {reload: true, include: 'actions'});
      return this.store.findRecord('call-flow', params.call_flow_id, {
        include: 'actions'
      });
    }

    willTransition() {
      console.debug('Rollback disabled for call flows');
      return false;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "willTransition", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype)), _class));
  _exports.default = CallFlowEditRoute;
});