define("frontend2/controllers/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let BaseController = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class BaseController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "confirmDialog", _descriptor, this);
    }

    /* NAVIGATION HELPERS */
    gotoRoute(route) {
      console.debug(`Goto route: ${route}`);
      this.transitionToRoute(route);
    }

    gotoRouteWithModel(route, item) {
      console.debug(`Goto route with model: ${route}`);
      this.transitionToRoute(route, item);
    }
    /* CONFIRMATION DIALOG */


    /* MODEL OPERATIONS */
    async saveCS(changeset, route) {
      console.debug('Saving changeset');
      const creating = changeset.isNew;
      changeset.save().then(() => {
        console.debug('Save complete');
        Promise.allSettled(this.saveDirtyModelRelations(this.model)).then(() => {
          this.notify.success('Saved successfully'); // Do post save handlers

          if (typeof this.postSaveHook === "function") {
            // Run postSaveHook and wait for promise
            this.postSaveHook(creating).then(transition => {
              console.debug(`Successfully handled post save hook (transition: ${transition})`);

              if (transition) {
                this.transitionToRoute(route);
              }
            });
          } else {
            this.transitionToRoute(route);
          }
        });
      }).catch(err => {
        this.notify.error('Save failed');
        console.info('Save failed');
        console.info(err); // Handle server errors

        this.model.errors.forEach(({
          attribute,
          message
        }) => {
          changeset.addError(attribute, message);
        });
        this.model.rollbackAttributes();
      });
    } // Iterate all model relationships and check if we need to save any related records
    // Returns list of save promises


    saveDirtyModelRelations(model) {
      let postSavePromises = [];
      model.eachRelationship((name, relationship) => {
        if (relationship.kind === 'hasMany') {
          model[name].forEach(item => {
            if (item.hasDirtyAttributes && !item.isSaving) {
              console.debug(`Saving changed object on relation ${name}`);
              postSavePromises.push(item.save());
            }
          });
        }
      });
      return postSavePromises;
    }

    deleteModel(route) {
      console.debug('Deleting model');
      this.model.destroyRecord().then(() => {
        console.debug('Delete ok');
        this.notify.success('Deleted OK...'); // self.notify.success('Deleted OK...');

        this.transitionToRoute(route);
      }).catch(() => {
        this.notify.error('Delete failed');
        console.debug('Deleted failed');
      });
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "gotoRoute", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "gotoRoute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "gotoRouteWithModel", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "gotoRouteWithModel"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "confirmDialog", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "saveCS", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "saveCS"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteModel", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "deleteModel"), _class.prototype)), _class));
  _exports.default = BaseController;
});