define("frontend2/routes/call-flow/create", ["exports", "frontend2/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let CallFlowCreateRoute = (_dec = Ember._action, (_class = class CallFlowCreateRoute extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "templateName", 'call-flow.edit');

      _defineProperty(this, "controllerName", 'call-flow');
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.showNameDialog = true;
    }

    model() {
      return this.store.createRecord('call-flow', {
        template: true
      });
    }

    willTransition() {
      console.debug('Rollback disabled for call flows');
      return false;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "willTransition", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype)), _class));
  _exports.default = CallFlowCreateRoute;
});