define("frontend2/routes/application", ["exports", "@ember-data/adapter/error"], function (_exports, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ApplicationRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = class ApplicationRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "apiSession", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _defineProperty(this, "queryParams", {
        embed: {
          refreshModel: false
        },
        page_header: {
          refreshModel: false
        }
      });
    }

    error(error)
    /* transition */
    {
      console.error('An error occurred:');
      console.error(error); // Handle unauthorized errors

      if (error instanceof _error.UnauthorizedError) {
        console.warn('Unauthorized error');

        if (this.apiSession.embedAccessToken) {
          console.warn('Redirect to ember error page');
          this.transitionTo('embed-error');
        } else {
          console.warn('Unauthorized, redirecting to login');
          this.transitionTo('login');
        }
      }

      let errStr = 'Unknown error';

      if ('errors' in error && error.isAdapterError) {
        // Just grab first error
        const err = error.errors[0];
        errStr = `An unexpected API error<br><b>(${err.status}) ${err.detail}</b>`;
      }

      if ('readyState' in error) {
        try {
          const err = JSON.parse(error.responseText);
          errStr = err.error;
        } catch (ex) {
          errStr = 'Malformed error message from api server??';
        }
      }

      this.notify.alert({
        html: errStr
      }, {
        closeAfter: 10000
      });
    }

    model(params, transition) {
      this.intl.setLocale(['en-gb', 'en']); // Exclude these routes from authentication

      const unauthenticatedRoutes = ['login', 'recover-password', 'link.activate', 'link.error', 'link.recover', 'embed-error'];

      if (unauthenticatedRoutes.includes(transition.targetName)) {
        console.debug(`Unauthenticated route: ${transition.targetName}`);
        return;
      }

      console.debug(`Authenticated route: ${transition.targetName}`); // Set embedded mode

      if (params.embed) {
        this.apiSession.setEmbeddedMode(params.embed, params.page_header);
      } else {
        if (!this.apiSession.isAuthenticated) {
          console.info('User not authenticated, redirecting to login page');
          this.transitionTo('login');
          return;
        }
      } // console.debug('User is authenticated');


      return this.apiSession.loadCurrentUserInfo(true);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "apiSession", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "error", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "error"), _class.prototype)), _class));
  _exports.default = ApplicationRoute;
});