define("frontend2/components/drop-zone", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    {{on "drop" this.dropItem}}
    {{on "dragover" this.dragOver}}
    {{on "dragleave" this.dropLeave}}
    {{on "dragenter" this.dropEnter}}
          class="dropZone {{if this.overDropZone "dropZone-over"}} text-center">
  
    {{#if this.args.dragState}}
      {{yield}}
    {{else}}
      <div class="clearZone">Empty Call Action Spot</div>
    {{/if}}
  </div>
  */
  {
    "id": "83QR91TO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"dropZone \",[30,[36,0],[[32,0,[\"overDropZone\"]],\"dropZone-over\"],null],\" text-center\"]]],[4,[38,1],[\"drop\",[32,0,[\"dropItem\"]]],null],[4,[38,1],[\"dragover\",[32,0,[\"dragOver\"]]],null],[4,[38,1],[\"dragleave\",[32,0,[\"dropLeave\"]]],null],[4,[38,1],[\"dragenter\",[32,0,[\"dropEnter\"]]],null],[12],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"args\",\"dragState\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"clearZone\"],[12],[2,\"Empty Call Action Spot\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "meta": {
      "moduleName": "frontend2/components/drop-zone.hbs"
    }
  });

  let DropZoneComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class DropZoneComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "overDropZone", _descriptor, this);
    }

    dropItem(dragEvent) {
      dragEvent.preventDefault();
      const d = dragEvent.dataTransfer.getData('application/json');

      if (!d) {
        console.error('Drag information is invalid or missing');
        return;
      }

      const info = JSON.parse(d);
      info.to_position_after = this.args.position;
      console.debug(`Drop operation ${info.operation} from position ${info.from_position} to after position ${info.to_position_after}`);
      this.overDropZone = false;
      this.args.onDrop(info);
    }

    dragOver(dragEvent) {
      dragEvent.preventDefault();
      dragEvent.dataTransfer.dropEffect = "move";
    }

    dropEnter() {
      // console.debug('Drag is entered Drop Zone');
      this.overDropZone = true;
    }

    dropLeave() {
      // console.debug('Drag has left drop zone');
      this.overDropZone = false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "overDropZone", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "dropItem", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "dropItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragOver", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "dragOver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dropEnter", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "dropEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dropLeave", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "dropLeave"), _class.prototype)), _class));
  _exports.default = DropZoneComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DropZoneComponent);
});